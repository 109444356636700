<template>
  <!-- 会员列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addMember()">新建</el-button>
      <!-- <el-button class="itemBtn btnColor">删除</el-button> -->
      <el-button class="itemBtn btnColor" @click="modifybut()">修改</el-button>
      <el-button class="itemBtn btnColor" @click="addressGL()"
        >地址管理</el-button
      >
      <el-button
        v-if="!Tools.NoHuoMao()"
        class="itemBtn btnColor"
        @click="changPwd()"
        >修改会员密码</el-button
      >
      <el-button
        v-if="Tools.NoHuoMao()"
        class="itemBtn btnColor"
        @click="changPwd2()"
        >重置会员登录密码</el-button
      >
      <el-button class="itemBtn btnColor" @click="changPwd3()"
        >重置会员余额支付密码</el-button
      >
      <el-button class="itemBtn btnColor" @click="changeMenberId()"
        >修改会员号</el-button
      >
      <!-- <el-button class="itemBtn btnColor">加入黑名单</el-button> -->
      <el-button class="itemBtn btnColor" @click="EnableBut(0)">禁用</el-button>
      <el-button class="itemBtn btnColor" @click="EnableBut(1)">启用</el-button>

      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 1)"
                v-model="queryData.memberId"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembers"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.memberId"
                  :value="item.memberId + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 2)"
                v-model="queryData.nickname"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembersName"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.nickname"
                  :value="item.nickname + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.memberId"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.nickname"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div> -->
        </div>
        <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <!-- <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip> -->
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 根据拒收状态获取拒收单信息 -->
    <div>
      <el-dialog
        title="拒收单信息"
        :visible.sync="dia_jushou"
        width="40%"
        @close="handleClose_jushou"
      >
        <div>
          <el-table
            :data="juShouInfoData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_js"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="courierNumber" label="快递单号">
            </el-table-column>
            <el-table-column prop="handleResult" label="处理结果">
            </el-table-column>
            <el-table-column prop="handleStatus" label="处理状态">
            </el-table-column>
            <el-table-column prop="handleTime" label="处理时间">
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_js"
            :total="total_js"
            :sizeList="sizeList_js"
            :size="size_js"
            @handleSizeChange="handleSizeChange_js"
            @handleCurrentChange="handleCurrentChange_js"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_jushou = false">取 消</el-button>
          <el-button type="primary" @click="saveJuShouBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 弹窗 -->
    <div>
      <el-dialog
        :title="diaTitle_timeType"
        :visible.sync="dia_timeType"
        width="40%"
        @close="handleClose_timeType"
      >
        <div>
          <el-table
            :data="timeTypeData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_timeType"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
            </el-table-column>
            <el-table-column
              v-if="timeType == 1"
              prop="number"
              label="入库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="number"
              label="出库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="number"
              label="打包称重单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="billStorepackNumber"
              label="仓库打包单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="channelNumber"
              label="渠道单号"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="packageId"
              label="包裹id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="intoStorageId"
              label="入库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="outStorageId"
              label="出库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="storepackId"
              label="打包单id"
            ></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_timeType"
            :total="total_timeType"
            :sizeList="sizeList_timeType"
            :size="size_timeType"
            @handleSizeChange="handleSizeChange_timeType"
            @handleCurrentChange="handleCurrentChange_timeType"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_timeType = false">取 消</el-button>
          <el-button type="primary" @click="saveTimeTypesBtn">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改会员 -->
      <el-dialog
        :title="memtitle"
        :visible.sync="NewMember"
        :before-close="closebut"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            ref="userarray"
            :model="userarray"
            :rules="rules"
            status-icon
          >
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-input v-model="userarray.memberId"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input v-model="userarray.userName"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="手机号码:">
                <el-input v-model="userarray.phone"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="状态:" prop="userStatus">
                <el-select v-model="userarray.userStatus">
                  <el-option
                    v-for="(itme, index) in stateList"
                    :key="index"
                    :label="itme.name"
                    :value="itme.statusVal"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员等级:">
                <el-select v-model="userarray.confMemberlevelId">
                  <el-option
                    v-for="(itme, index) in GradeList"
                    :key="index"
                    :label="itme.levelName"
                    :value="itme.levelId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="通讯地址:">
                <el-input v-model="userarray.postalAddress"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="备注:">
                <el-input v-model="userarray.comment"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmbut">确认</el-button>
        </div>
      </el-dialog>
      <!-- 修改会员密码  货猫 -->
      <el-dialog :title="memtitle" :visible.sync="changePWD" @close="cloasePWD">
        <div class="newForm">
          <el-form
            label-width="120px"
            :model="cpwdData"
            :rules="rules"
            status-icon
          >
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-input v-model="cpwdData.memberId"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input v-model="cpwdData.userName"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="密码:">
                <el-input v-model="cpwdData.pwd"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="确认密码:">
                <el-input v-model="cpwdData.surePwd"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmCPWD">确认</el-button>
        </div>
      </el-dialog>

      <!-- 修改会员密码 其他租户-->
      <el-dialog
        title="重置会员密码"
        :visible.sync="changePWD2"
        @close="cloasePWD2"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            ref="cpwdData2ref"
            :model="cpwdData2"
            :rules="rulesCPWD"
          >
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-input readonly v-model="cpwdData2.memberId"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input readonly v-model="cpwdData2.userName"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="密码:" prop="password">
                <el-input
                  v-model="cpwdData2.password"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="确认密码:" prop="password">
                <el-input
                  v-model="cpwdData2.checkPassword"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmCPWD2">确认</el-button>
        </div>
      </el-dialog>

      <!-- 重置会员支付密码-->
      <el-dialog
        title="重置会员支付密码"
        :visible.sync="changePWD3"
        @close="cloasePWD3"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            ref="cpwdData2ref"
            :model="cpwdData3"
            :rules="rulesCPWD"
          >
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-input readonly v-model="cpwdData3.memberId"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input readonly v-model="cpwdData3.userName"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="密码:" prop="password">
                <el-input
                  v-model="cpwdData3.password"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="确认密码:" prop="checkPassword">
                <el-input
                  v-model="cpwdData3.checkPassword"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmCPWD3">确认</el-button>
        </div>
      </el-dialog>

      <!-- 修改会员号 -->
      <el-dialog
        title="修改会员号"
        :visible.sync="changeMid"
        @close="cloaseMid"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            :model="changeMIds"
            :rules="rules"
            status-icon
          >
            <div class="queryItem1">
              <el-form-item label="原会员号:">
                <el-input disabled v-model="changeMIds.oldMenber"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="新会员号:">
                <el-input v-model="changeMIds.newMenber"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmMid">确认</el-button>
        </div>
      </el-dialog>
      <!-- 新增会员(货猫) -->
      <el-dialog
        title="新建会员"
        :visible.sync="showAddMember"
        @close="closeAddMember"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            ref="userarray"
            :model="addMemeberData"
            :rules="rules"
            status-icon
          >
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-input v-model="addMemeberData.memberId"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="密码:">
                <input
                  type="password"
                  name="password"
                  style="position: fixed; left: -9999px"
                />
                <el-input
                  autocomplete="off"
                  type="password"
                  v-model="addMemeberData.password"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="确认密码:">
                <el-input
                  autocomplete="off"
                  type="password"
                  v-model="addMemeberData.checkPassword"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmbutAdd">确认</el-button>
        </div>
      </el-dialog>

      <!-- 新增会员(v2) -->
      <el-dialog
        title="新建会员"
        :visible.sync="showAddMemberV2"
        @close="closeAddMemberV2"
      >
        <!-- 会员信息 -->
        <div class="newFormV2">
          <el-form
            :rules="rules1"
            label-width="100px"
            ref="userarray"
            :model="memberInfoReq"
            status-icon
          >
            <h3>设置会员信息:</h3>
            <div class="queryItemV2">
              <el-form-item label="会员名称:" prop="nickname">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.nickname"
                ></el-input>
              </el-form-item>
              <el-form-item label="会员昵称:" prop="tab">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.tab"
                ></el-input>
              </el-form-item>
              <el-form-item label="会员等级:" prop="confMemberlevelId">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.confMemberlevelId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in GradeList"
                    :label="item.levelName"
                    :value="item.levelId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="默认仓库:" prop="storageId">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.storageId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in storageArr"
                    :label="item.storageName"
                    :value="item.id + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="国家/地区:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.areaCountryId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in AreaCountryList"
                    :label="item.chineseName + ' (' + item.englishName + ')'"
                    :value="item.areaCountryId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="归属业务员:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.businessEmployeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="专属财务:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.financeEmployeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专属客服:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.serviceEmployeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item style="width: 100%" label="通讯地址:">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.address"
                ></el-input>
              </el-form-item>
              <el-form-item style="width: 100%" label="备注:">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.comment"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 账号信息 -->
        <div class="newFormV2">
          <el-form
            :rules="rules2"
            label-width="100px"
            ref="userarray"
            :model="userInfoReq"
            status-icon
          >
            <h3>设置账号信息:</h3>
            <div class="queryItemV2">
              <el-form-item label="账号:" prop="username">
                <el-input
                  v-model="userInfoReq.username"
                  auto-complete="off"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码:" prop="password">
                <el-input
                  placeholder="请输入"
                  auto-complete="new-password"
                  v-model="userInfoReq.password"
                ></el-input>
                <!-- <el-input
                  :type="inputType"
                  @input="inputPwd"
                  placeholder="请输入"
                  auto-complete="new-password"
                  v-model="userInfoReq.password"
                ></el-input> -->
              </el-form-item>
              <el-form-item label="会员余额:" prop="amount">
                <el-input
                  placeholder="请输入"
                  type="number"
                  v-model="userInfoReq.amount"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱:" prop="email">
                <el-input
                  placeholder="请输入"
                  v-model="userInfoReq.email"
                ></el-input>
              </el-form-item>
              <el-form-item label="认证邮箱:" prop="mailIsAuth">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.mailIsAuth"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in isMailAuth"
                    :label="item.name"
                    :value="item.val + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手机:" prop="phone">
                <el-input
                  placeholder="请输入"
                  v-model="userInfoReq.phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="直接推荐人:" prop="upUserId">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.upUserId"
                  placeholder="请选择"
                  clearable
                  filterable
                  remote
                  :remote-method="
                    (val) =>
                      getUserAccountChooseList(true, val, 'member:list_type')
                  "
                  @visible-change="
                    getUserAccountChooseList($event, '', 'member:list_type')
                  "
                >
                  <el-option
                    v-for="(item, index) in AccountChooseList"
                    :label="item.nickName"
                    :value="item.userId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户状态:" prop="userStatus">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.userStatus"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in userStatusList"
                    :label="item.showName"
                    :key="index"
                    :value="item.statusVal"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户类型:" prop="userType">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.userType"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in userTypeList"
                    :label="item.showName"
                    :key="index"
                    :value="item.statusVal"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关联员工:"
                prop="employeeId"
                v-if="userInfoReq.userType == 'employee:user_type'"
              >
                <el-select
                  class="inputs"
                  v-model="userInfoReq.employeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关联运营商:"
                prop="spreadId"
                v-if="userInfoReq.userType == 'agent:user_type'"
              >
                <el-select
                  class="inputs"
                  v-model="userInfoReq.spreadId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button
            class=""
            style="margin-right: 20px"
            @click="showAddMemberV2 = false"
            >关闭</el-button
          >
          <el-button class="seniorbut" @click="confirmbutAddV2">确认</el-button>
        </div>
      </el-dialog>

      <!-- 修改会员(v2) -->
      <el-dialog
        title="修改会员"
        :visible.sync="showAddMemberV3"
        @close="closeAddMemberV2"
      >
        <!-- 会员信息 -->
        <div class="newFormV2">
          <el-form
            :rules="rules1"
            label-width="100px"
            ref="userarray"
            :model="memberInfoReq"
            status-icon
          >
            <h3>设置会员信息:</h3>
            <div class="queryItemV2">
              <el-form-item label="会员名称:" prop="nickname">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.nickname"
                ></el-input>
              </el-form-item>
              <el-form-item label="会员昵称:" prop="tab">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.tab"
                ></el-input>
              </el-form-item>
              <el-form-item label="会员等级:" prop="confMemberlevelId">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.confMemberlevelId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in GradeList"
                    :label="item.levelName"
                    :value="item.levelId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="默认仓库:" prop="storageId">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.storageId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in storageArr"
                    :label="item.storageName"
                    :value="item.id + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="国家/地区:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.areaCountryId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in AreaCountryList"
                    :label="item.chineseName + ' (' + item.englishName + ')'"
                    :value="item.areaCountryId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="归属业务员:">
                <el-select
                  class="inputs"
                  v-model="memberInfoReq.businessEmployeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <!-- <el-form-item label="专属财务:">
                <el-select class="inputs" v-model="memberInfoReq.financeEmployeeId" placeholder="请选择" clearable
                  filterable>
                  <el-option v-for="(item, index) in memberList" :label="item.nickname" :value="item.nickname + ''"
                    :key="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专属客服:">
                <el-select class="inputs" v-model="memberInfoReq.serviceEmployeeId" placeholder="请选择" clearable
                  filterable>
                  <el-option v-for="(item, index) in memberList" :label="item.nickname" :value="item.nickname + ''"
                    :key="index">
                  </el-option>
                </el-select>
              </el-form-item> -->

              <el-form-item style="width: 100%" label="通讯地址:">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.address"
                ></el-input>
              </el-form-item>
              <el-form-item style="width: 100%" label="备注:">
                <el-input
                  placeholder="请输入"
                  v-model="memberInfoReq.comment"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 账号信息 -->
        <div class="newFormV2">
          <el-form
            :rules="rules2"
            label-width="100px"
            ref="userarray"
            :model="userInfoReq"
            status-icon
          >
            <h3>设置账号信息:</h3>
            <div class="queryItemV2">
              <el-form-item label="账号:" prop="username">
                <el-input
                  v-model="userInfoReq.username"
                  auto-complete="off"
                  disabled
                  placeholder=""
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="密码:" prop="password">
                <el-input
                  placeholder="请输入"
                  auto-complete="new-password"
                  v-model="userInfoReq.password"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="会员余额:" prop="amount">
                <el-input
                  disabled
                  placeholder="请输入"
                  type="number"
                  v-model="userInfoReq.amount"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱:" prop="email">
                <el-input
                  placeholder="请输入"
                  v-model="userInfoReq.email"
                ></el-input>
              </el-form-item>
              <el-form-item label="认证邮箱:" prop="mailIsAuth">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.mailIsAuth"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in isMailAuth"
                    :label="item.name"
                    :value="item.val"
                    :key="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手机:" prop="phone">
                <el-input
                  placeholder="请输入"
                  v-model="userInfoReq.phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="直接推荐人:" prop="upUserId">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.upUserId"
                  placeholder="请选择"
                  clearable
                  filterable
                  remote
                  :remote-method="
                    (val) =>
                      getUserAccountChooseList(true, val, 'member:list_type')
                  "
                  @visible-change="
                    getUserAccountChooseList($event, '', 'member:list_type')
                  "
                >
                  <el-option
                    v-for="(item, index) in AccountChooseList"
                    :label="item.nickName"
                    :value="item.userId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户状态:" prop="userStatus">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.userStatus"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in userStatusList"
                    :label="item.showName"
                    :key="index"
                    :value="item.statusVal"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户类型:" prop="userType">
                <el-select
                  class="inputs"
                  v-model="userInfoReq.userType"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in userTypeList"
                    :label="item.showName"
                    :key="index"
                    :value="item.statusVal"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关联员工:"
                prop="employeeId"
                v-if="userInfoReq.userType == 'employee:user_type'"
              >
                <el-select
                  class="inputs"
                  v-model="userInfoReq.employeeId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关联运营商:"
                prop="spreadId"
                v-if="userInfoReq.userType == 'agent:user_type'"
              >
                <el-select
                  class="inputs"
                  v-model="userInfoReq.spreadId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in EmployeeChooseList"
                    :label="item.employeeName"
                    :value="item.employeeId + ''"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmbutAddV3">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    paging,
    sorttable,
  },
  data() {
    return {
      diatype: "add",
      //状态下拉
      stateList: [
        {
          name: "正常",
          statusVal: "zheng_chang:user_status",
        },
        {
          name: "禁用",
          statusVal: "jin_yong:user_status",
        },
      ],
      //客户等级下拉
      GradeList: [],

      rules: {
        userStatus: [
          {
            required: true,
            message: "请选择用户状态",
            trigger: ["change", "blur"],
          },
        ],
      },
      // 新建会员v2
      rules1: {
        nickname: [{ required: true, message: "请输入", trigger: "blur" }],
        confMemberlevelId: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
        storageId: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
      },
      rules2: {
        username: [{ required: true, message: "请输入", trigger: "blur" }],
        // upUserId: [{ required: true, message: "请输入", trigger: "blur" }],
        password: [{ required: true, message: "请输入", trigger: "blur" }],
        email: [{ required: true, message: "请输入", trigger: "blur" }],
        // mailIsAuth: [
        //   { required: true, message: "请选择", trigger: ["change", "blur"] },
        // ],
        userType: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
      },
      rulesCPWD: {
        checkPassword: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        password: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },

      userarray: {
        confMemberlevelId: "", //会员等级id
        id: "", //会员id
        memberId: "", //会员号
        userStatus: "", //用户状态
        businessEmployeeId: "", //专属业务员工id
        comment: "", //备注
        financeEmployeeId: "", //专属财务员工id
        phone: "", //手机号
        postalAddress: "", //通讯地址
        serviceEmployeeId: "", //专属客户员工id
        userName: "", //会员名称
      },
      tape: "add",
      FormData: {
        aaa: "",
      },
      memtitle: "会员管理",
      showAddMember: false, //新建会员弹窗
      showAddMemberV2: false, //新建会员弹窗
      showAddMemberV3: false, //修改会员弹窗
      NewMember: false, //修改会员弹窗
      changePWD: false, //修改会员弹窗
      changePWD2: false, //修改会员弹窗
      changePWD3: false, //重置会支付密码
      changeMid: false, //修改会员号
      queryData: {
        memberId: "", //会员号
        nickname: "", //会员名称
      },

      times: [], //开始结束时间
      timeType: "0", //时间类型
      memberId: "", //会员号
      memberId_s: "", //弹窗输入的会员号
      daBaoPiCi: "", //打包批次号
      daBaoPiCi_s: "", //弹窗打包批次号
      rejectionStatus: "", //拒收状态
      packageIds_js: [], //拒收包裹id,
      packageIds_sqdb: [], //申请打包包裹id,
      packageIds_times: [], //各种时间的包裹id,
      //包裹状态
      packageStatusList: [],
      //拒签状态
      rejectionStatusList: [],
      //验货状态
      inspectionStatusList: [],

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "75vh",
      tabelHeadeTitle: [
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员余额",
          field: "amount",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "会员等级",
          field: "levelName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "状态",
          field: "userStatusShow",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "手机号码",
          field: "phone",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "通讯地址",
          field: "address",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: "备注",
          field: "comment",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "160",
          sort: 8,
          isShow: true,
          isTotal: false,
        },

        {
          name: "修改时间",
          field: "updateTime",
          width: "160",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tabelHeadeTitle_dg: [
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员昵称",
          field: "tab",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员余额",
          field: "amount",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "会员等级",
          field: "levelName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "状态",
          field: "userStatusShow",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "手机号码",
          field: "phone",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "会员账号",
          field: "username",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "绑定邮箱",
          field: "loginMail",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "认证邮箱",
          field: "mailIsAuthShow",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "归属代理商",
          field: "spreadUserName",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "关联员工",
          field: "relationStaffName",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "直接推荐人",
          field: "upUserName",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "间接推荐人",
          field: "upUpUserName",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "账户类型",
          field: "userTypeShow",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "通讯地址",
          field: "address",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: "备注",
          field: "comment",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "160",
          sort: 8,
          isShow: true,
          isTotal: false,
        },

        {
          name: "修改时间",
          field: "updateTime",
          width: "160",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "huiyuanguanli111", //页面标识
      selectTableData: [], //选中的数据
      //===============

      memberInfoData: [], //会员信息
      pageNum_member: 1, //页码
      total_member: 0,
      sizeList_mb: [10, 20, 50, 100, 200],
      size_member: 10, //一页几条
      selectMemberData: [], //选中的会员信息
      // 根据时间获取不同单的信息
      timeTypeData: [], //会员信息
      pageNum_timeType: 1, //页码
      total_timeType: 0,
      sizeList_timeType: [10, 20, 50, 100, 200],
      size_timeType: 10, //一页几条
      selectdanhaoData: [], //选中的单号信息
      dia_timeType: false,
      diaTitle_timeType: "", //弹窗标题
      // 根据拒收状态获取拒收单的信息
      juShouInfoData: [], //会员信息
      pageNum_js: 1, //页码
      total_js: 0,
      sizeList_js: [10, 20, 50, 100, 200],
      size_js: 10, //一页几条
      selectJSData: [], //选中的单号信息
      dia_jushou: false,
      // 打包批次号-目的地-渠道代理获取申请打包单信息
      sqdbData: [], //会员信息
      pageNum_sqdb: 1, //页码
      total_sqdb: 0,
      sizeList_sqdb: [10, 20, 50, 100, 200],
      size_sqdb: 10, //一页几条
      selectSqdbData: [], //选中的单号信息
      dia_sqdb: false,
      endtime: "",
      sarttime: "",

      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      addMemeberData: {
        memberId: "",
        password: "",
        checkPassword: "",
      },
      cpwdData: {
        pwd: "",
        surePwd: "",
        userId: "",
        userName: "",
        memberId: "",
      },
      cpwdData2: {
        password: "",
        checkPassword: "",
        userId: "",
        userName: "",
        memberId: "",
      },
      cpwdData3: {
        password: "",
        checkPassword: "",
        userMemberId: "",
        userName: "",
        memberId: "",
      },
      changeMIds: {
        oldMenber: "",
        newMenber: "",
      },
      memberList: [],
      // v2新建会员==>会员信息
      memberInfoReq: {
        address: "", //地址
        areaCountryId: "", //国家id
        businessEmployeeId: "", //归属业务员id
        comment: "", //备注
        confMemberlevelId: "1", //会员等级id
        financeEmployeeId: "", //专属财务id
        nickname: "", //会员昵称
        serviceEmployeeId: "", //专属客服id
        storageId: "", //默认仓库id
        tab: "",
      },
      // v2新建会员==>账号信息
      userInfoReq: {
        // appleId: "", //苹果 openid
        // facebookOpenId: "", //facebookOpenId openid
        // jingdongOpenId: "", //京东 openid
        // pddOpenId: "", //拼多多 openid
        // taobaoOpenId: "", //淘宝 openid
        // wechatOpenId: "", //微信 openid
        amount: "0", //余额
        email: "", //邮箱
        employeeId: "", //员工id(userType=salesman:user_type时传)
        mailIsAuth: "0", //是否认证邮箱
        password: "", //密码
        phone: "", //手机
        spreadId: "", //运营商id(userType=agent:user_type时传)
        upUserId: "", //直接推荐人id
        userStatus: "zheng_chang:user_status", //用户状态
        userType: "member:user_type", //	账户类型: member:user_type=会员 agent:user_type=运营商 employee:user_type=员工 salesman:user_type=业务员
        username: "", // 账号
      },

      AreaCountryList: [], //国家列表
      EmployeeChooseList: [], //员工列表
      AccountChooseList: [], //账号列表
      storageArr: [], //仓库下拉列表
      isMailAuth: [
        {
          name: "已认证",
          val: 1,
        },
        {
          name: "未认证",
          val: 0,
        },
      ],
      inputType: "text",
      userTypeList: [],
      userStatusList: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    if (this.Tools.NoHuoMao()) {
      this.tabelHeadeTitle = this.tabelHeadeTitle_dg;
    }
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-10); //10天前
    this.times = [this.sarttime, this.endtime];

    this.getAreaContryList(); //获取国家下拉列表
    this.getMemberLeveDownList(); //获取会员等级下拉列表
    this.getuserEmployeeChooseList(); //获取员工下拉列表
    this.getStorageIdArr(); //获取仓库下拉列表
  },
  mounted() {
    const that = this;
    this.getData();
    this.getVipList();
    this.getUsreTypeList();
    this.getuserStatusist(); //会员状态
  },
  watch: {},
  methods: {
    getUsreTypeList() {
      //会员类型
      Api.getStatusValList({
        tableAndFieldName: "user_type_info_key_v1.user_type",
      }).then((res) => {
        this.userTypeList = res.data.result || [];
      });
    },
    getuserStatusist() {
      //会员状态
      Api.getStatusValList({
        tableAndFieldName: "user_v1.user_status",
      }).then((res) => {
        this.userStatusList = res.data.result || [];
      });
    },
    inputPwd(e) {
      if (e) {
        this.inputType = "password";
      } else {
        this.inputType = "text";
      }
    },
    // 获取国家下拉列表
    getAreaContryList() {
      Api.getAreaContryList_q().then((res) => {
        let datas = res.data.result || [];
        this.AreaCountryList = datas;
      });
    },
    // 获取员工下拉列表
    getuserEmployeeChooseList() {
      Api.userEmployeeChooseList().then((res) => {
        let datas = res.data.result || [];
        this.EmployeeChooseList = datas;
      });
    },

    //账号下拉列表
    getUserAccountChooseList(e, val, listType) {
      if (!e) {
        return false;
      }
      Api.adminUserList({
        nickName: val,
        listType: listType,
      }).then((res) => {
        let datas = res.data.result.data || [];
        this.AccountChooseList = datas;
      });
    },
    // 仓库列表
    getStorageIdArr() {
      Api.getStorageList().then((res) => {
        this.storageArr = res.data.result || [];
      });
    },
    // 选择会员
    huiayu(e, num) {
      if (!e) {
        this.getVipList();
        this.queryData.memberId = "";
        this.queryData.nickname = "";
        return false;
      }
      if (num == 1) {
        this.memberList.forEach((item) => {
          if (item.memberId == e) {
            this.queryData.nickname = item.nickname;
          }
        });
      }
      if (num == 2) {
        this.memberList.forEach((item) => {
          if (item.nickname == e) {
            this.queryData.memberId = item.memberId;
          }
        });
      }
      this.queryBtn_ok();
    },
    // 远程搜索会员
    getMembers(e) {
      if (e.length > 0) {
        this.getVipList(e);
      } else {
        this.getVipList();
      }
    },
    // 远程搜索会员名
    getMembersName(e) {
      if (e.length > 0) {
        this.getVipList(e, "name");
      } else {
        this.getVipList();
      }
    },
    getVipList(e, type) {
      let val = e || "";
      Api.getMemberDownList({
        memberId: type ? "" : val,
        nickname: type == "name" ? val : "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);

      // @row-click="rowclick"
    },

    //获取会员等级下拉列表
    getMemberLeveDownList() {
      Api.getMemberLeveDownList().then((res) => {
        this.GradeList = res.data.result || [];
      });
    },
    // 新建确认
    confirmbutAdd() {
      let params = {
        memberId: tools.getOrderNum(this.addMemeberData.memberId) || [],
        password: this.addMemeberData.password,
        checkPassword: this.addMemeberData.checkPassword,
      };

      let sign = tools.getSign(params);
      params.sign = sign;
      Api.registerMember(params).then((res) => {
        if (res.data.result.errmsg) {
          this.$message.warning(res.data.result.errmsg || "新建成功");
          return false;
        } else {
          this.$message.success("新建成功");
          this.getData();
        }

        this.showAddMember = false;
      });
    },

    // 新建会员v2接口
    confirmbutAddV2() {
      let param = {
        memberInfoReq: this.memberInfoReq,
        userInfoReq: this.userInfoReq,
      };

      param.sign = tools.getSign(param);

      Api.dgMemberAddMember(param).then((res) => {
        this.$message.success(res.data.message);
        this.showAddMemberV2 = false;
        this.getData();
      });
    },
    // 修改会员v2接口
    confirmbutAddV3() {
      let param = {
        memberInfoReq: this.memberInfoReq,
        userInfoReq: this.userInfoReq,
      };
      console.log("param");
      console.log(param);
      delete param.password;
      param.sign = tools.getSign(param);

      Api.updateMemberV2(param).then((res) => {
        this.$message.success(res.data.message);
        this.showAddMemberV3 = false;
        this.getData();
      });
    },
    // 修改会员密码
    changPwd() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
      } else {
        this.changePWD = true;
        this.cpwdData.memberId = selectTableData[0].memberId;
        this.cpwdData.userName = selectTableData[0].nickName;
        this.cpwdData.userId = selectTableData[0].userId;
      }
    },
    // 修改会员密码
    changPwd2() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
      } else {
        this.changePWD2 = true;
        this.cpwdData2.memberId = selectTableData[0].memberId;
        this.cpwdData2.userName = selectTableData[0].nickName;
        this.cpwdData2.userId = selectTableData[0].userId;
      }
    },
    // 重置会员余额支付密码
    changPwd3() {
      // resetPayPwd
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
      } else {
        this.changePWD3 = true;
        this.cpwdData3.memberId = selectTableData[0].memberId;
        this.cpwdData3.userName = selectTableData[0].nickName;
        this.cpwdData3.userMemberId = selectTableData[0].id || "";
      }
    },

    // 修改会员号
    changeMenberId() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
      } else {
        this.changeMIds.oldMenber = selectTableData[0].memberId;
        this.changeMIds.userMemberId = selectTableData[0].id;
        this.changeMid = true;
      }
    },
    //关闭修改密码弹窗
    cloasePWD() {
      this.changePWD = false;
    },
    //关闭修改密码弹窗
    cloasePWD2() {
      this.changePWD2 = false;
      this.cpwdData2 = {
        password: "",
        checkPassword: "",
        userId: "",
        userName: "",
        memberId: "",
      };
    },
    //关闭重置支付密码弹窗
    cloasePWD3() {
      this.changePWD3 = false;
      this.cpwdData3 = {
        password: "",
        checkPassword: "",
        userMemberId: "",

        userName: "",
        memberId: "",
      };
    },
    //关闭修改密码弹窗
    cloaseMid() {
      this.changeMid = false;
    },
    // 确认修改密码
    confirmCPWD() {
      let param = {
        pwd: this.cpwdData.pwd,
        surePwd: this.cpwdData.surePwd,
        userId: this.cpwdData.userId,
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.updateMemberPwd(param).then((res) => {
        this.$message.success(res.data.message || "修改成功");
        this.changePWD = false;
      });
    },
    // 确认修改密码
    confirmCPWD2() {
      this.$refs.cpwdData2ref.validate((valid) => {
        if (valid) {
          if (this.cpwdData2.password !== this.cpwdData2.checkPassword) {
            this.$message.warning("两次密码不一致");
            return false;
          }
          let param = {
            password: this.cpwdData2.password,
            checkPassword: this.cpwdData2.checkPassword,
            userId: this.cpwdData2.userId,
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.resetPasswordV2(param).then((res) => {
            this.$message.success(res.data.message || "修改成功");
            this.changePWD2 = false;
          });
        } else {
          return false;
        }
      });
    },

    // 确认重置余额支付密码
    confirmCPWD3() {
      if (this.cpwdData3.password !== this.cpwdData3.checkPassword) {
        this.$message.warning("两次密码不一致");
        return false;
      }
      let param = {
        password: this.cpwdData3.password,
        checkPassword: this.cpwdData3.checkPassword,
        userMemberId: this.cpwdData3.userMemberId,
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.resetPayPwd(param).then((res) => {
        this.$message.success(res.data.message || "修改成功");
        this.cloasePWD3();
      });
    },
    // 确认修改会员号
    confirmMid() {
      if (!this.changeMIds.newMenber) {
        this.$message.success("请输入新的会员号");
        return false;
      }
      let param = {
        memberId: this.changeMIds.newMenber,
        userMemberId: this.changeMIds.userMemberId || "",
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.updateMemberIdV2(param).then((res) => {
        this.$message.success(res.data.message || "修改成功");
        this.changeMid = false;
        this.getData();
      });
    },
    //弹窗确认(修改)
    confirmbut() {
      this.$refs.userarray.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          // 修改
          if (this.diatype == "modifybut") {
            let param = {
              confMemberlevelId: this.userarray.confMemberlevelId, //会员等级id
              id: this.userarray.id, //会员id
              memberId: this.userarray.memberId, //会员号
              userStatus: this.userarray.userStatus, //用户状态
              businessEmployeeId: this.userarray.businessEmployeeId, //专属业务员工id
              comment: this.userarray.comment, //备注
              financeEmployeeId: this.userarray.financeEmployeeId, //专属财务员工id
              phone: this.userarray.phone, //手机号
              postalAddress: this.userarray.postalAddress, //通讯地址
              serviceEmployeeId: this.userarray.serviceEmployeeId, //专属客户员工id
              userName: this.userarray.userName, //会员名称
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.updateMember(param).then((res) => {
              console.log(res.data);
              if (res.data.message == "修改成功") {
                this.$message.success(res.data.message || "操作成功");
                setTimeout(() => {
                  this.closebut();
                  this.getData();
                }, 1000);
              }
            });
          } else if (this.diatype == "add") {
            // 新增
          }
        }
      });
    },
    //关闭弹窗
    closebut() {
      this.$refs.userarray.resetFields();
      this.userarray = {
        confMemberlevelId: "", //会员等级id
        id: "", //会员id
        memberId: "", //会员号
        userStatus: "", //用户状态
        businessEmployeeId: "", //专属业务员工id
        comment: "", //备注
        financeEmployeeId: "", //专属财务员工id
        phone: "", //手机号
        postalAddress: "", //通讯地址
        serviceEmployeeId: "", //专属客户员工id
        userName: "", //会员名称
      };
      this.NewMember = false;
    },
    //新增会员
    addMember() {
      // this.diatype = "add";
      // this.memtitle = "新建会员";
      // this.showAddMember = true;

      // 新的
      if (tools.NoHuoMao()) {
        this.showAddMemberV2 = true;
      } else {
        this.showAddMember = true;
      }
      this.AccountChooseList = [];
    },
    // 关闭新建弹窗
    closeAddMember() {
      this.addMemeberData = {
        memberId: "",
        password: "",
        checkPassword: "",
      };
    },
    // 关闭新建弹窗(v2)
    closeAddMemberV2() {
      this.memberInfoReq = {
        address: "", //地址
        areaCountryId: "", //国家id
        businessEmployeeId: "", //归属业务员id
        comment: "", //备注
        confMemberlevelId: "1", //会员等级id
        financeEmployeeId: "", //专属财务id
        nickname: "", //会员昵称
        serviceEmployeeId: "", //专属客服id
        storageId: "", //默认仓库id
        tab: "",
      };
      this.userInfoReq = {
        // appleId: "", //苹果 openid
        // facebookOpenId: "", //facebookOpenId openid
        // jingdongOpenId: "", //京东 openid
        // pddOpenId: "", //拼多多 openid
        // taobaoOpenId: "", //淘宝 openid
        // wechatOpenId: "", //微信 openid
        amount: "0", //余额
        email: "", //邮箱
        employeeId: "", //员工id(userType=salesman:user_type时传)
        mailIsAuth: "0", //是否认证邮箱
        password: "", //密码
        phone: "", //手机
        spreadId: "", //运营商id(userType=agent:user_type时传)
        upUserId: "", //直接推荐人id
        userStatus: "zheng_chang:user_status", //用户状态
        userType: "member:user_type", //	账户类型: member:user_type=会员 agent:user_type=运营商 employee:user_type=员工 salesman:user_type=业务员
        username: "", // 账号
      };
    },
    //启用 == 1  禁用==0
    EnableBut(enableType) {
      let _this = this;
      let selectTableData = this.selectTableData || [];
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return;
      }
      let ids = [];
      selectTableData.forEach((item, index) => {
        ids.push(item.id);
      });
      let strs = "启用";
      if (enableType == "0") {
        strs = "禁用";
      }
      this.$confirm("是否" + strs + "当前选择的会员?", "提示:")
        .then(() => {
          let param = {
            enableType: enableType,
            id: ids,
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.memberEnable(param).then((res) => {
            if ((res.data.status = '"success"')) {
              this.$message.success(res.data.message || "操作成功");
              _this.getData();
            }
          });
        })
        .catch(() => {});
    },

    // 地址管理
    addressGL() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
      } else {
        this.$router.push({
          path: "/MemberManagement/addressList",
          query: {
            userId: selectTableData[0].userId,
            nickName: selectTableData[0].nickName,
            memberId: selectTableData[0].memberId,
            pagetTypa: "isBtn",
          },
        });
      }
    },
    //修改
    modifybut() {
      let selectTableData = this.selectTableData;
      console.log(selectTableData);
      if (selectTableData.length != 1) {
        this.$message.warning("请选择一个会员");
        return false;
      }

      if (tools.NoHuoMao()) {
        // 非货猫
        Api.memberDetailV2({ userId: selectTableData[0].userId }).then(
          (res) => {
            // console.log("会员详情");
            // console.log(res);
            this.memberInfoReq = res.data.result.memberInfoReq || {};
            this.userInfoReq = res.data.result.userInfoReq || {};
            this.showAddMemberV3 = true;
            this.AccountChooseList = [
              {
                userId: this.userInfoReq.upUserId,
                nickName: this.userInfoReq.upUpUserName || "请选择",
              },
            ];
          }
        );
      } else {
        // 货猫的
        this.diatype = "modifybut";
        this.memtitle = "修改会员";
        this.NewMember = true;
        let id = selectTableData[0].id; //会员id
        Api.memberDetail({ id: id }).then((res) => {
          console.log(res.data.result);
          let userarray = res.data.result || [];
          this.userarray = {
            confMemberlevelId: userarray.confMemberlevelId || "", //会员等级id
            id: id, //会员id
            memberId: userarray.memberId || "", //会员号
            userStatus: userarray.userStatus || "", //用户状态
            businessEmployeeId: userarray.businessEmployeeId || "", //专属业务员工id
            comment: userarray.comment || "", //备注
            financeEmployeeId: userarray.financeEmployeeId || "", //专属财务员工id
            phone: userarray.phone || "", //手机号
            postalAddress: userarray.postalAddress || "", //通讯地址
            serviceEmployeeId: userarray.serviceEmployeeId || "", //专属客户员工id
            userName: userarray.nickname || "", //会员名称
          };
        });
      }
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 输入批次号,回车
    getDaBaoDan(e) {
      // if (this.daBaoPiCi) {
      this.dia_sqdb = true;
    },
    // 打包批次号-目的地-渠道代理获取申请打包单信息
    getPackPackageBillForPackage() {
      let pids = this.daBaoPiCi_s || "";
      if (pids) {
        pids = pids.split(",");
      }
      let param = {
        areaCountryId: "", //国家id
        channelId: "", //渠道id
        number: JSON.stringify(pids), //打包批次号
        pageStart: this.pageNum_sqdb,
        pageTotal: this.size_sqdb,
      };
      Api.getPackPackageBillForPackage(param).then((res) => {
        this.sqdbData = res.data.result.data || [];
        this.size_sqdb = res.data.result.pageTotal || 0;
        this.total_sqdb = res.data.result.pageCount || 0;
      });
    },

    // 根据会员号获取会员信息
    getMemberByMemberId() {
      let mids = this.memberId_s;
      // if (mids) {
      //     mids = mids.split(',')
      // }
      let param = {
        memberId: mids,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getMemberByMemberId(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
      });
    },
    // 选择拒收状态
    changeRejectStatus(e) {
      this.rejectionStatus = e;
      this.getRejectionBillForPackage();
      this.dia_jushou = true;
    },

    // 根据拒收状态获取拒收单信息
    getRejectionBillForPackage() {
      let param = {
        rejectionStatus: this.rejectionStatus,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getRejectionBillForPackage(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
      });
    },
    //关闭会员信息弹窗
    handleClose_member() {
      this.memberInfoData = [];
      this.selectMemberData = [];
    },
    //关闭拒收单信息弹窗
    handleClose_jushou() {
      this.juShouInfoData = [];
      this.selectJSData = [];
    },
    //关闭申请打包信息弹窗
    handleClose_sqdb() {
      this.sqdbData = [];
      this.selectSqdbData = [];
      this.dia_sqdb = false;
    },

    //拒收单==>确定按钮
    saveJuShouBtn() {
      ////console.log("确定");
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_js = arr;
      this.dia_jushou = false;
    },
    //申请打包信息==>确定按钮
    savesqdbBtn() {
      ////console.log("确定");
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_sqdb = arr;
      this.dia_jushou = false;
    },
    //会员信息==>选择表格操作
    handleSelectionChange_menber(e) {
      this.selectMemberData = e;
    },
    //拒收单信息==>选择表格操作
    handleSelectionChange_js(e) {
      this.selectJSData = e;
    },
    //申请打包==>选择表格操作
    handleSelectionChange_sqdb(e) {
      this.selectSqdbData = e;
    },
    //选择表格操作
    handleSelectionChange_timeType(e) {
      this.selectdanhaoData = e;
      ////console.log(e);
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
      ////console.log(e);
    },

    //关闭时间单号信息弹窗
    handleClose_timeType() {
      this.timeTypeData = [];
      this.selectdanhaoData = [];
    },
    //根据时间xxx==>确定按钮
    saveTimeTypesBtn() {
      ////console.log("确定");
      let arr = [];

      for (var i = 0; i < this.selectdanhaoData.length; i++) {
        arr.push(this.selectdanhaoData[i].packageId);
      }
      this.packageIds_times = arr;
      this.dia_timeType = false;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "会员管理列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        ////console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 5; //60是顶部导航 ,5==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      let that = this;
      formData.pageStart = that.pageNum;
      formData.pageTotal = that.size;
      if (this.Tools.NoHuoMao()) {
        // 代购集运 有邮箱之类的
        Api.bmg_getMemberList(formData).then((res) => {
          if (res.data.status == "success") {
            that.className = res.data.result.className || "";
            that.tableData = res.data.result.data || [];
            that.total = res.data.result.pageCount || 0;
            this.fetTableHeight();
          }
        });
      } else {
        //普通集运 货猫的
        Api.getMemberList(formData).then((res) => {
          if (res.data.status == "success") {
            that.className = res.data.result.className || "";
            that.tableData = res.data.result.data || [];
            that.total = res.data.result.pageCount || 0;
            this.fetTableHeight();
          }
        });
      }
    },
    // 查询按钮
    queryBtn_ok() {
      ////console.log(this.queryData);
      // let orderNos = this.queryData.val1;
      // this.getStr(orderNos);
      // 别的参数暂时没有
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        memberId: "", //会员号
        nickname: "", //会员名称
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    //分页(会员信息的)
    handleSizeChange_mb(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_mb(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
    //分页(拒收单信息的)
    handleSizeChange_js(val) {
      this.size = val;
      this.getRejectionBillForPackage();
    },
    handleCurrentChange_js(val) {
      this.pageNum = val;
      this.getRejectionBillForPackage();
    },
    //分页(申请打包单信息的)
    handleSizeChange_sqdb(val) {
      this.size = val;
      this.getPackPackageBillForPackage();
    },
    handleCurrentChange_sqdb(val) {
      this.pageNum = val;
      this.getPackPackageBillForPackage();
    },
    //分页(时间类型单信息的)
    handleSizeChange_timeType(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_timeType(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }

  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.newForm {
  margin-left: 80px;

  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;

    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.queryItem1 {
  width: 100%;
  margin: 0 3%;
  margin-top: 10px;
}

.queryItem2 {
  width: 100%;
}

.footbox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.newFormV2 {
  padding-bottom: 30px;

  .queryItemV2 {
    display: flex;
    flex-wrap: wrap;

    /deep/ .el-form-item {
      width: 30%;
      margin-bottom: 20px;
      min-width: 220px;
      margin-right: 2%;
    }
  }
}
</style>
